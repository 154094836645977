@import url(https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap);
*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::-moz-selection {
  background: #2ddab8;
  color: white;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #0c0f13;
  overflow: hidden;
  font-family: 'Sulphur Point', sans-serif;
  color: #8b8b8b;
  font-size: 0.9rem;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus, 
a:hover {
  color: #D40749;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* .loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
} */

.loading {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #D40749;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}
.loading:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #8b8b8b;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
.loading:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FFFFE9;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__title:hover {
  color: #FFFFE9;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  -webkit-transform: translate3d(0, -100%, 0) !important;
          transform: translate3d(0, -100%, 0) !important;
}

.canvas > div {
  z-index: 10000000;
}

@media screen and (max-width: 600px) {
  .hide__mobile {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}

@media screen and (min-width: 53em) {
	body {
		overflow: hidden;
	}
	.frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
		align-content: space-between;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links'
							'... ...'
							'... nav';
	}
	.frame__title {
    grid-area: title;
	}
	.frame__links {
		grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
		justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}
